body {
  font-family: "Open Sans";
  font-size: 16px;
  padding: 0px;
  margin: 0px;
}

a {
  text-decoration-line: none;
  color: #168D13;
}

* {
  box-sizing: border-box;
}

.s-drop {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
}

.apdexBar {
  height: 16px;
  width: 120px;
  border-radius: 4px;
  background: linear-gradient(to right, #CB2C2C, #FFB800, #168D13);
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.59 8.59003L12 13.17L7.41 8.59003L6 10L12 16L18 10L16.59 8.59003Z' fill='black'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right .5rem top 50%;
  background-size: 24px;
  padding-right: 32px;
}

input, input:focus, button, button:focus, textarea, textarea:focus {
  -webkit-appearance:none !important;
  -moz-appearance:none !important;
  outline: none !important;
}

input, input:focus, button, button:focus {
  border: 0 !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #979797;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #979797;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #979797;
}

div:focus {
  outline-style: dotted !important;
  outline-width: 1px;
  outline-color: #979797;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.fade-enter {
  opacity: 0;
  transition: opacity .5s ease;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity .5s ease;
}

.fade-exit {
  opacity: 1;
  transition: opacity .5s ease;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity .5s ease;
}

.sb-fix-v::-webkit-scrollbar, .sb-fix-h::-webkit-scrollbar {
    -webkit-appearance: none;
}

.sb-fix-v::-webkit-scrollbar:vertical {
    width: 11px;
}

.sb-fix-h::-webkit-scrollbar:horizontal {
    height: 11px;
}

.sb-fix-v::-webkit-scrollbar-thumb, .sb-fix-h::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
}

.policies-content {
  height: calc(100vh - 80px);
}

@media screen and (min-width: 48rem) {
  .policies-content {
    height: calc(100vh - 180px);
  }
}

@keyframes animatePedingMonitorProgress {
  from {
    width: 0%;
  }
  to {
    left: 100%;
  }
}

.animate-peding-monitor-progress {
  animation: animatePedingMonitorProgress 20s ease-in-out;
  animation-fill-mode: both;
}

textarea:disabled, input:disabled, textarea:disabled::placeholder, input:disabled::placeholder {
  color: #C8C8C8;
  background-color: #f8f8f8;
}
